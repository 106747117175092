module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"grid-item column large-")
    ; __append(escapeFn( dimensions.large ))
    ; __append("-col medium-")
    ; __append(escapeFn( dimensions.medium ))
    ; __append("-col small-")
    ; __append(escapeFn( dimensions.small ))
    ; __append("-col\"><div class=\"mantra-card no-padding animate\" data-card=\"mantra-card\" data-id=\"")
    ; __append(escapeFn( id ))
    ; __append("\"><h4>")
    ; __append( content )
    ; __append("</h4> ")
    ;  if (attribution) { 
    ; __append("<h5>")
    ; __append( attribution )
    ; __append("</h5>")
    ;  } 
    ; __append(" </div></div>")
  }
  return __output;

}