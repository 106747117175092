/*
 * File: general.utilities.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 16, 2025 at 10:28 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Constants
const {
	OBFUSCATE_REGEX,
	OBFUSCATE_TEXT_REPLACE,
	AVAILABLE_LOCALES,
	FULL_STOP_PUNCTUATION
} = require('../constants/general.constants');


/**
 * Utilities
 */

const formatTextRedactions = (content = '') => content.replace(OBFUSCATE_REGEX, (str) => {
	const element = str.slice(2, -2);
	if (element === '') {
		return `(${OBFUSCATE_TEXT_REPLACE})`;
	}
	return `(${element})`;
});

const reduceToBaseURL = (p) => {
	let path = p;
	if (path) {
		AVAILABLE_LOCALES.forEach((locale) => {
			const testArray = path.split(locale);
			if (testArray.length > 1 && testArray[0] === '/') {
				const testPath = testArray[1];
				if ((testPath.length === 0 || testPath.charAt(0) === '/' || testPath.charAt(0) === '?')) {
					path = path.replace(locale, '').replace('//', '/');
				}
			}
		});
	}
	return path;
};

const splitSentences = (content) => {

	// Create regex
	const regex = new RegExp(`[^${FULL_STOP_PUNCTUATION.join('')}]+[${FULL_STOP_PUNCTUATION.join('')}]+`, 'g');

	// Find matches
	const matches = content.match(regex);

	// Return sentences
	return matches ? matches.map((m) => m.trim()) : [content];
};

const chunkString = (content, lengthLimit) => {

	// Check if chunking is needed
	if (content <= lengthLimit) {
		return [content];
	}

	// Split sentences
	const sentences = splitSentences(content);

	// Initialize chunks
	let chunks = [''];

	// Create chunks
	let chunkIndex = 0;
	sentences.forEach((sentence) => {
		if ((chunks[chunkIndex].length + sentence.length + 1) <= lengthLimit) {
			chunks[chunkIndex] += ` ${sentence}`;
		} else {
			chunks.push(sentence);
			chunkIndex += 1;
		}
	});

	// Clean chunks
	chunks = chunks.filter(Boolean).map((c) => c.trim());

	// Return chunks
	return chunks.length > 0 ? chunks : [content];
};


/**
 * Exports
 */

module.exports = {
	formatTextRedactions,
	reduceToBaseURL,
	chunkString
};
