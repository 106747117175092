/*
 * File: metrics.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: February 16, 2025 at 2:27 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Utilities
const { getBrowserEmbedId } = require('./utilities');


/**
 * Exports
 */

exports.handlePageView = (source, entityId, localeId, countryId, stateObj) => {

	// Get embed cookie
	const embedId = getBrowserEmbedId(stateObj);

	// Record view
	const params = {
		source, entity_id: entityId, locale_id: localeId, country_id: countryId, embed_id: embedId, url_path: window.location.pathname,
	};
	Parse.Cloud.run('recordViewWithParameters', params);
};

exports.recordAction = (id, data, localeId, countryId, stateObj) => {

	// Get embed cookie
	const embedId = getBrowserEmbedId(stateObj);

	// Record view
	const params = {
		id, data, locale_id: localeId, country_id: countryId, embed_id: embedId, url_path: window.location.pathname
	};
	Parse.Cloud.run('recordActionWithParameters', params);
};
