/*
 * File: stories.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: February 16, 2025 at 2:22 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Utilities
const { getComponentState } = require('../modules/general');
require('../modules/navigation');
const { handlePageView } = require('../modules/metrics');

// Handlers
const storiesHandler = require('../handlers/stories-handler');

// Constants
const { SOURCES } = require('../../../constants/general.constants');


/**
 * Init
 */

// Handle document ready
$(document).ready(() => {

	// Get question data
	const { locale, state: stateObj } = getComponentState();

	// Record page view
	handlePageView(SOURCES.STORIES, null, locale.locale_id, locale.country_id, stateObj);
});

// Initialize stories handler
storiesHandler.initialize(getComponentState());
