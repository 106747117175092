/*
 * File: general.constants.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 27, 2025 at 11:04 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Roles
 */

exports.ROLES = {
	STANDARD: 'standard',
	ANALYTICS_ONLY: 'analytics_only',
	LIMITED: 'limited',
	REVIEWER: 'reviewer',
	SPECIALIST: 'specialist',
	ADMIN: 'admin',
	SUPER: 'super',
};


/**
 * Versions
 */

exports.VERSIONS = {
	TERMS_VERSION: '0.9.0',
	PRIVACY_VERSION: '0.9.0',
	SECURITY_VERSION: '0.9.0',
	ACCESSIBILITY_VERSION: '0.9.0',
	COOKIE_STATEMENT_VERSION: '0.9.0'
};


/**
 * Available Locales
 */

exports.AVAILABLE_LOCALES = [ // Note: This is in order of translation priority
	'en',
	'es',
	'ja'
];


/**
 * Available Countries
 */

exports.AVAILABLE_COUNTRIES = [
	'us', // United States
	'ar', // Argentina
	'au', // Australia
	'bo', // Bolivia
	'ca', // Canada
	'cl', // Chile
	'co', // Colombia
	'cr', // Costa Rica
	'cu', // Cuba
	'do', // Dominican Republic
	'ec', // Ecuador
	'sv', // El Salvador
	'gq', // Equatorial Guinea
	'gt', // Guatemala
	'hn', // Honduras
	'ie', // Ireland
	'jp', // Japan
	'mx', // Mexico
	'ni', // Nicaragua
	'nz', // New Zealand
	'pa', // Panama
	'py', // Paraguay
	'pe', // Perú
	'pr', // Puerto Rico
	'es', // Spain
	'gb', // United Kingdom
	'uy', // Uruguay
	've', // Venezuela
];


/**
 * Sources
 */

exports.SOURCES = {
	STORIES: 'stories',
	STORY: 'story',
	QUESTIONS: 'questions',
	QUESTION: 'question',
	RESOURCES: 'resources',
	SHARE: 'share',
	SUBMIT: 'submit',
	RECORD: 'record',
	UPDATE: 'update',
	ARTIST: 'artist',
	EXHIBIT: 'exhibit',
	EXHIBITS: 'exhibits',
	GUESTBOOK: 'guestbook',
	ALL: 'all',
};


/**
 * Referral Sources
 */

exports.REFERRAL_SOURCES = {
	WEBSITE_CARD: 'website_card',
	WEBSITE_FOOTER: 'website_footer',
	EMAIL: 'email',
	QUESTION_ANSWER: 'answer',
	BLOG_POST: 'blog_post'
};


/**
 * Review Statuses
 */

exports.REVIEW_STATUS = {
	WAITING_FOR_REVIEW: 'WAITING_FOR_REVIEW',
	IN_REVIEW: 'IN_REVIEW',
	REQUIRES_APPROVAL: 'REQUIRES_APPROVAL',
	REVIEW_COMPLETED: 'REVIEW_COMPLETED',
};


/**
 * Reactions
 */

exports.REACTIONS = {
	reaction_a: {
		code: 'reaction_a',
		icon: 'reaction-a-icon',
	},
	reaction_b: {
		code: 'reaction_b',
		icon: 'reaction-b-icon',
	},
	reaction_c: {
		code: 'reaction_c',
		icon: 'reaction-c-icon',
	},
	reaction_d: {
		code: 'reaction_d',
		icon: 'reaction-d-icon',
	},
	reaction_e: {
		code: 'reaction_e',
		icon: 'reaction-e-icon',
	},
};


/**
 * Update Actions
 */

exports.UPDATE_ACTIONS = {
	REMOVE_STORY: 'action_remove_story',
	HIDE_STORY: 'action_hide_story',
	SHOW_STORY: 'action_show_story',
	ALLOW_SOCIAL: 'action_allow_social',
	DISALLOW_SOCIAL: 'action_disallow_social',
	ALLOW_EMAIL: 'action_allow_email',
	DISALLOW_EMAIL: 'action_disallow_email',
	ALLOW_PUBLISH_MAIN: 'action_allow_publish_main',
	DISALLOW_PUBLISH_MAIN: 'action_disallow_publish_main',
	ALLOW_NEWSLETTER: 'action_allow_newsletter',
	DISALLOW_NEWSLETTER: 'action_disallow_newsletter',
	UPDATE_EMAIL: 'action_update_email',
	UPDATE_TAGS: 'action_update_tags'
};


/**
 * Triggers
 */

exports.CONTENT_TRIGGERS = {
	SELF_HARM: 'SELF_HARM',
	HOMICIDAL: 'HOMICIDAL',
	DEROGATORY_CONTENT: 'DEROGATORY_CONTENT'
};


/**
 * Story Media Types
 */

exports.STORY_MEDIA_TYPES = {
	NARRATIVE: 'narrative',
	ARTWORK: 'artwork'
};


/**
 * General
 */

exports.OBFUSCATE_OPEN_CODE = '{~';
exports.OBFUSCATE_CLOSE_CODE = '~}';
exports.OBFUSCATE_REGEX = /{~.*?~}/g;
exports.OBFUSCATE_TEXT_REPLACE = '****';
exports.EXCERPT_MIN = 250;
exports.EXCERPT_MAX = 550;
exports.SCROLL_LOAD_TOP = -1200;
exports.SCROLL_LOAD_BOTTOM = 600;
exports.HOPE_ROUTER_DESIGNATION = 'event';
exports.IS_IMPACT_VISIBLE = true;
exports.SHOW_OVERLAY = true;
exports.DEFAULT_TIMEZONE = 'America/New_York';
exports.DEFAULT_LOCALE = 'en-US';
exports.DEFAULT_COUNTRY = 'us';
exports.PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;


/**
 * Headers
 */

exports.USER_IMPERSONATION_HEADER = 'x-user-imp-token';


/**
 * Cookies
 */

exports.STORIES_VIEWED_COOKIE = 'stories-viewed-config';
exports.ACTIVE_STORIES_FILTERS_COOKIE = 'active-s-filters-config';
exports.ACTIVE_QUESTIONS_FILTERS_COOKIE = 'active-q-filters-config';
exports.ACTIVE_STORIES_MEDIA_COOKIE = 'active-s-media-config';
exports.ACTIVE_QUESTIONS_SEARCH_COOKIE = 'active-q-search-config';
exports.REACTION_SCHEMA_COOKIE = 'reaction-schema';
exports.UPDATES_SCHEMA_COOKIE = 'updates-schema';
exports.SESSION_TOKEN_COOKIE = 'e_s_token';
exports.LOCALE_COOKIE = 'pl_locale';
exports.COUNTRY_COOKIE = 'pl_country';
exports.EMBED_COOKIE = 'pl_embed';
exports.CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
exports.CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
exports.CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
exports.CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';
exports.CONSENT_COOKIE_EXPIRATION = 1 * 365 * 24 * 60 * 60 * 1000; // One year
exports.LOCALE_COOKIE_EXPIRATION = 1 * 365 * 24 * 60 * 60 * 1000; // One year
exports.COUNTRY_COOKIE_EXPIRATION = 1 * 365 * 24 * 60 * 60 * 1000; // One year
exports.EMBED_COOKIE_EXPIRATION = 1 * 365 * 24 * 60 * 60 * 1000; // One year
exports.SESSION_COOKIE_EXPIRATION = 1 * 365 * 24 * 60 * 60 * 1000; // One year
exports.USER_IMPERSONATION_COOKIE = 'user_imp_token';


/**
 * Cookie Domain
 */

exports.COOKIE_DOMAIN = () => (process.env.ENV === 'production' ? 'ourwave.org' : process.env.APP_URL.replace('https://', '').replace('http://', ''));


/**
 * Query Parameters
 */

exports.ACTIVE_STORIES_FILTERS_QUERY = 'filters';
exports.ACTIVE_QUESTIONS_FILTERS_QUERY = 'filters';
exports.ACTIVE_STORIES_MEDIA_QUERY = 'media';
exports.ACTIVE_QUESTIONS_SEARCH_QUERY = 'search';
exports.EMBED_MODE = 'mode';
exports.EMBED_PARTNER_ID = 'pid';
exports.EMBED_VIEW = 'view';


/**
 * File Upload Limits (Bytes)
 */

exports.FILE_UPLOAD_LIMITS = {
	SURVIVOR_MEDIA: 10000000, // 10 MB
	GUESTBOOK_MEDIA: 10000000, // 10 MB
	RESOURCE_MEDIA: 10000000, // 10 MB
};


/**
 * Image Upload Resolution Limits (Pixels)
 */

exports.FILE_UPLOAD_RES_LIMITS = {
	SURVIVOR_MEDIA: {
		MIN: 100, // 100 pixels
		MAX: 5000 // 5000 pixels
	},
	GUESTBOOK_MEDIA: {
		MIN: 50, // 50 pixels
		MAX: 2000 // 2000 pixels
	},
	RESOURCE_MEDIA: {
		MIN: 300, // 300 pixels
		MAX: 2000 // 2000 pixels
	}
};


/**
 * Acceptable Image Ratios
 */

exports.ACCEPTABLE_IMAGE_RATIOS = {
	SURVIVOR_MEDIA: 2.8, // 1:2.8 (Both width and height)
	GUESTBOOK_MEDIA: 2.8, // 1:2.8 (Both width and height)
	RESOURCE_MEDIA: 2.8 // 1:2.8 (Both width and height)
};


/**
 * Acceptable File Types
 */

exports.ACCEPTABLE_FILE_TYPES = {
	SURVIVOR_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	},
	GUESTBOOK_MEDIA: {
		EXTENSIONS: [
			'png'
		],
		MIME_TYPES: [
			'image/png'
		]
	},
	RESOURCE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	},
};


/**
 * User Actions
 */

exports.USER_ACTIONS = {
	STARTED_GROUNDING_EXERCISE: 'started_grounding_exercise',
	SUPPORT_CARD_CLICK: 'support_card_click',
	SUPPORT_CARD_VIDEO_PLAY: 'support_card_video_play',
	RESOURCE_LINK_CLICK: 'resource_link_click',
	STORY_EXTERNAL_LINK_CLICK: 'story_external_link_click',
	ANSWER_EXTERNAL_LINK_CLICK: 'answer_external_link_click',
	MESSAGE_EXTERNAL_LINK_CLICK: 'story_external_link_click',
};


/**
 * Tag Families
 */

exports.TAG_FAMILIES = {
	location: {
		id: 'location'
	},
	perpetrator: {
		id: 'perpetrator'
	},
	race: {
		id: 'race'
	},
	orientation: {
		id: 'orientation'
	},
	gender: {
		id: 'gender'
	},
	identification: {
		id: 'identification',
		combineWith: 'gender'
	},
	age: {
		id: 'age'
	},
	experiences: {
		id: 'experiences'
	},
	industry: {
		id: 'industry'
	},
	influence: {
		id: 'influence'
	},
	region: {
		id: 'region'
	}
};


/**
 * Content Types
 */

exports.INTERNAL_CONTENT_TYPES = {
	STORY: 'story',
	MANTRA: 'mantra',
	SUPPORT_IMAGE: 'support_image',
	SUPPORT_VIDEO: 'support_video'
};


/**
 * Full Stop Punctuation
 */

exports.FULL_STOP_PUNCTUATION = [
	'!',
	'.',
	'?',
	'։',
	'؟',
	'۔',
	'܀',
	'܁',
	'܂',
	'߹',
	'।',
	'॥',
	'၊',
	'။',
	'።',
	'፧',
	'፨',
	'᙮',
	'᠃',
	'᠉',
	'᥄',
	'᥅',
	'᪨',
	'᪩',
	'᪪',
	'᪫',
	'᭚',
	'᭛',
	'᭞',
	'᭟',
	'᰻',
	'᰼',
	'᱾',
	'᱿',
	'‼',
	'‽',
	'⁇',
	'⁈',
	'⁉',
	'⸮',
	'。',
	'꓿',
	'꘎',
	'꘏',
	'꛳',
	'꛷',
	'꡶',
	'꡷',
	'꣎',
	'꣏',
	'꤯',
	'꧈',
	'꧉',
	'꩝',
	'꩞',
	'꩟',
	'꫰',
	'꫱',
	'꯫',
	'﹒',
	'﹖',
	'﹗',
	'！',
	'．',
	'？',
	'｡',
	'𑁇',
	'𑁈',
	'𑂾',
	'𑂿',
	'𑃀',
	'𑃁',
	'𑅁',
	'𑅂',
	'𑅃',
	'𑇅',
	'𑇆',
];
